import { twMerge } from "tailwind-merge";
import "../styles/resume.css";
import BackgroundPicture from "../assets/background.jpg";
// import Texture from "../assets/absurdity.png";
import SchoolPicture from "../assets/umsl.jpg";
import ProfilePicture from "../assets/me.png";
import Cplusplus from "../assets/logos/icons8-c.svg";
import JavaLogo from "../assets/logos/icons8-java.svg";
import JSLogo from "../assets/logos/icons8-javascript.svg";
import ReactLogo from "../assets/logos/icons8-react-js.svg";
import HTMLLogo from "../assets/logos/icons8-html.svg";
import CSSLogo from "../assets/logos/icons8-css.svg";
import Photoshop from "../assets/logos/icons8-photoshop.svg";
import Illustrator from "../assets/logos/icons8-illustrator.svg";
import AfterEfffects from "../assets/logos/icons8-aftereffects.svg";
import Premiere from "../assets/logos/icons8-adobepre.svg";
import TailWindCSS from "../assets/logos/icons8-tailwindcss.svg";
import Typescript from "../assets/logos/icons8-typescript.svg";
import STL from "../assets/STL.png";
import { motion} from "framer-motion";


const skills = [
    { src: Photoshop, name: "Photoshop" },
    { src: Illustrator, name: "Illustrator" },
    { src: AfterEfffects, name: "AfterEffects" },
    { src: Premiere, name: "Premiere" },
    { src: Cplusplus, name: "C++" },
    { src: JavaLogo, name: "Java" },
    { src: JSLogo, name: "JavaScript" },
    { src: ReactLogo, name: "React" },
    { src: Typescript, name: "TypeScript" },
    { src: TailWindCSS, name: "TailwindCSS" },
    { src: HTMLLogo, name: "HTML" },
    { src: CSSLogo, name: "CSS" },
  ];




const Resume = () => {

    const Description = "Web Fullstack Developer, Designer, and Software Engineer.";

    return (
        <section id="resume" className="relative h-[290vh] lg:h-[100vh] 3xl-[100vh] bg-color1">
            <div className="relative flex justify-center items-start w-full h-full">
                <div className="lg:sticky flex justify-center items-center lg:h-screen p-3 top-0 h-screen w-full">
                    <div className="w-full lg:w-[65vw] h-[100vh] lg:h-[80%] grid lg:grid-cols-6 lg:grid-rows-3 gap-4 justify-items-center items-center top-0">
                        <Box className="lg:row-span-3 lg:col-span-2 h-[75vh]">
                            <div className="flex flex-1 justify-center items-center w-full">
                                <img
                                src={ProfilePicture}
                                className="w-2/4 rounded-full bg-color1 hover:scale-150 hover:my-7 transition-all"
                                alt="Profile"
                                />
                            </div>
                            <div className="flex flex-1 flex-col justify-around items-center w-full px-2">
                                <h2 className="font-semibold text-[3.5vh] text-white text-center flex justify-center items-center border-pink1 border-b-4">
                                John Timmermann
                                </h2>
                                <p className="flex justify-center items-center text-center text-[2.5vh] drop-shadow-2xl flex-wrap">
                                {Description}
                                </p>
                                <a 
                                href="https://drive.google.com/file/d/1xMRO52A0Gu-8TNf8TExR2aGcstdpIbPu/view?usp=sharing"
                                
                                className="no-underline flex justify-center items-center gap-4 text-4xl lg:text-[2vh] text-white rounded-3xl w-1/2 h-16 
                                bg-color1/50 border hover:border-pink1 border-blue4 hover:scale-125 transition-all hover:text-pink1"
                                
                                target="_blank" 
                                rel="noreferrer"
                                >Resume<i class="bi bi-download"/>
                                </a>
                            </div>
                        </Box>
                        <Box className="lg:col-span-4">
                            <div className="w-full h-full flex flex-col justify-center items-center gap-4">
                                <h4 className="font-bold border-pink1 border-b-4 text-white text-[3vh]">
                                    Skills
                                </h4>
                                <div className="w-full inline-flex flex-nowrap">
                                {Array(2)
                                    .fill(skills)
                                    .map((skillsList, index) => (
                                    <ul
                                        key={index}
                                        className="flex items-center justify-center md:justify-start [&_li]:mx-8 [&_img]:max-w-32 animate-mobile-loop-scroll lg:animate-loop-scroll lg:pt-2"
                                    >
                                        {skillsList.map((icon, idx) => (
                                        <li key={idx} className="flex flex-col justify-center items-center bg-color1/50 rounded-3xl size-28">
                                            <img src={icon.src} alt={`${icon.name}`} className="w-20 3xl:w-20"/>
                                            {/* <h5 className="font-semibold">{`${icon.name}`}</h5> */}
                                        </li>
                                        ))}
                                    </ul>
                                    ))}
                                </div>
                            </div>
                        </Box>
                        <Box 
                        className="lg:col-span-2 border-none"
                        style={{
                            backgroundImage: `url(${SchoolPicture})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            backgroundPosition: "center"
                          }}
                        >
                            <div className="flex flex-1 flex-col justify-center items-center bg-color1/75 hover:opacity-0 transition-all duration-150 w-full h-full gap-3">
                                <h4 className="font-bold border-pink1 border-b-4 text-white text-[3vh]">
                                    Education
                                </h4>
                                <section className="flex flex-col justify-center items-center">
                                    <h3 className="drop-shadow-xl font-bold text-center text-3xl">
                                    University of Missouri - St. Louis
                                    </h3>
                                    <h5 className="text-center drop-shadow-xl text-lg">
                                    August 2022 - Present
                                    </h5>
                                </section>
                            </div>
                        </Box>
                        <Box className="lg:col-span-2">
                            <div className="flex flex-1 flex-col justify-center items-center w-full h-full gap-4">
                                <h4 className="font-bold border-pink1 border-b-4 text-white text-[3vh]">Work</h4>
                                <section className="flex flex-col justify-center items-center">
                                    <h3 className="drop-shadow-xl font-bold text-center text-3xl">
                                        Chick-Fil-A
                                    </h3>
                                    <h5 className="text-center drop-shadow-xl text-lg">
                                        April 2014 - Present
                                    </h5>
                                </section>
                            </div>
                        </Box>
                        <Box className="lg:col-span-3 over">
                            {/* <h4 className="font-bold border-pink1 border-b-4 text-white text-[3vh]">Socials</h4> */}
                            <div className="flex justify-evenly items-center w-full h-2/3 gap-4">
                                <SocialButtons
                                    name="linkedin"
                                    link="https://www.linkedin.com/in/johntimmermann/"
                                />
                                <SocialButtons
                                    name="github"
                                    link="https://github.com/JohnTimmermann"
                                />
                                <SocialButtons
                                    name="twitter-x"
                                    link="https://www.twitter.com/JohnTimmz"
                                />
                            </div>
                        </Box>
                        <Box className="lg:col-span-1">
                          <div className="flex flex-1 flex-col justify-center items-center hover:bg-color1/20 bg-color1/5 transition-colors duration-150 w-full h-full gap-3"/>
                          <motion.div
                          drag
                          dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
                          whileTap={{ scale: 2 }}
                          whileDrag={{ scale: 2 }}
                          className="flex justify-center items-center size-full rounded-3xl" 
                          style={{
                                backgroundImage: `url(${STL})`,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "350%",
                                backgroundPosition: "center"
                              }}>

                          </motion.div>
                        </Box>
                    </div>
                </div>
            </div>
        </section>
    )
}

const Box = (props) => {
    return (
        <motion.div
        initial={{
            y: '50%',
            opacity: '0%'
        }}
        whileInView={{
            y: '0',
            opacity: '100%',
        }}
        transition={{
            duration: '.5'
        }}
        viewport={{ once: true}}
        
        className={
            twMerge("rounded-3xl w-[95%] h-[40vh] lg:w-full lg:h-full backdrop-blur-md drop-shadow-md overflow-hidden bg-blue3/20 hover:bg-blue2/30 transition-colors duration-150", 
                props.className)
        }
        style={{
            // backgroundImage: `url("https://www.transparenttextures.com/patterns/simple-dashed.png")`,
            backgroundRepeat: "repeat",
            backgroundPosition: "center",
            // backgroundSize: "auto",
            backdropFilter: "blur(20px)",
            ...props.style,
        }}
        >
            <div className="flex flex-col justify-center items-center w-full h-full text-white">
                {props.children}
            </div>
        </motion.div>
    );
}

const SocialButtons = ({ name, link }) => {
    return (
      <a href={link} 
      className="no-underline hover:scale-125 hover:rotate-2 transition-all size-36 lg:size-40 rounded-3xl flex justify-center items-center bg-color1/50" 
      target="_blank" 
      rel="noreferrer"
      >
        <i
          className={`bi bi-${name} flex size-full justify-center items-center text-6xl lg:text-7xl text-blue4 hover:text-pink1`}
        />
      </a>
    );
  };

export default Resume;
