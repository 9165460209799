import { useRef } from 'react'
import Header from './components/Header';
import Resume from './components/Resume';
import Contact from './components/Contact';
import Hero from './components/Hero';

const App = (props) => {

    return (
        <div id="App" className='App bg-color1 scroll-smooth'>
            <Header/>
            <Hero/>
            <Resume/>
            <Contact/>
        </div>
    );
}

export default App;
